import React from 'react'
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
const antIcon = <LoadingOutlined style={{ fontSize: 70, color: '#1890ff' }} spin />;

const PageLoader = () => {
    return (
        <div style={{ height: "100vh", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Spin indicator={antIcon} />
        </div>
    )
}


export default PageLoader
