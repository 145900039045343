import React, { useReducer, createContext } from 'react';
import jwt from 'jsonwebtoken';

import { auth } from '../config/bootstrap'

const initialState = {
    user: null
}


if(localStorage.getItem(auth.publicKey)){
    try{
        const decodedToken = jwt.verify(localStorage.getItem(auth.publicKey), auth.secretKey);
        if(decodedToken.exp * 1000 < Date.now()){
            localStorage.removeItem(auth.publicKey)
            localStorage.removeItem(auth.userDetails);
        }
        else {
            initialState.user = JSON.parse(localStorage.getItem(auth.userDetails));
            initialState.user.token = localStorage.getItem(auth.publicKey);
        }
    }
    catch(error){
        initialState.user = null;
    }
   
}

const AuthContext = createContext({
    user: null,
    login: (userData) => {},
    logout: () => {}
})

const authReducer =(state, action) => {
    switch(action.type){
        case 'LOGIN':
            return{
                ...state,
                user: action.payload
            }
        case 'LOGOUT':
            return{
                ...state,
                user: null
        } 
        case 'UPDATE_USER':
            return{
                ...state,
                user: action.payload
        }    
        default:
            return state;
    }
}

const AuthProvider = (props) => {
    const [state, dispatch] = useReducer(authReducer, initialState);
    const login = (user) => {
        try{
            localStorage.setItem(auth.publicKey, user.token)
            localStorage.setItem(auth.publicKey, user.token)
            localStorage.setItem( auth.userDetails ,JSON.stringify(user.data))
            var userData = user.data;
            userData.token = user.token;
            dispatch({
                type: 'LOGIN',
                payload: userData
            })
        }
        catch(err){
            dispatch({
                type: 'LOGIN',
                payload: null
            })
        }
        
    }

    const logout = () => {
        localStorage.removeItem(auth.publicKey);
        localStorage.removeItem(auth.userDetails);
        dispatch( {type: 'LOGOUT'})
    }
    
    const updateUser = (user) => {
            try{
                localStorage.setItem( auth.userDetails ,JSON.stringify(user.data))
                var userData = user.data;
                userData.token = localStorage.getItem(auth.publicKey);
                dispatch({
                    type: 'UPDATE_USER',
                    payload: userData
                })
            }
            catch(err){
                dispatch({
                    type: 'LOGIN',
                    payload: null
                })
            }
    }

    return (
        <AuthContext.Provider 
          value= {{ user: state.user, login, logout, updateUser }} 
            {...props}
         />
    )
}

export { AuthProvider, AuthContext }