import React, { createContext, useState } from 'react';
import { GET_AFFILIATE_TOKEN_BY_ID } from '../config/routes'
import axios from 'axios';
import { auth } from '../config/bootstrap'
import jwt from 'jsonwebtoken';

const AffiliateContext = createContext({
});

let params = new URLSearchParams(document.location.search.substring(1));
let referalId = params.get("r") ? params.get("r") : false;


const AffiliateProvider = (props) => {

    const [ affiliateUser, setAffiliateUser] = useState(null)
    const getAffiliateToken = async () => {
        try{
            if(referalId){
                if(localStorage.getItem(auth.affiliateKey)){
                    try{
                        let  data = JSON.parse(localStorage.getItem(auth.affiliateUserDetails))
                        if(referalId !== data.id.toString()  ) {
                            var response = await axios.get(GET_AFFILIATE_TOKEN_BY_ID + referalId)
                            if(response.data){
                                localStorage.setItem(auth.affiliateKey, response.data.token)
                                localStorage.setItem( auth.affiliateUserDetails ,JSON.stringify(response.data.data))
                                setAffiliateUser(response.data.data)
                            } 
                        }
                        else {
                            try{
                                const decodedToken = jwt.verify(localStorage.getItem(auth.affiliateKey), auth.secretKey);
                                if(decodedToken.exp * 1000 < Date.now()){
                                    localStorage.removeItem(auth.affiliateKey)
                                    localStorage.removeItem(auth.affiliateUserDetails);
                                    response = await axios.get(GET_AFFILIATE_TOKEN_BY_ID + referalId)
                                    if(response.data){
                                        localStorage.setItem(auth.affiliateKey, response.data.token)
                                        localStorage.setItem( auth.affiliateUserDetails ,JSON.stringify(response.data.data))
                                        setAffiliateUser(response.data.data)
                                    } 
                                }
                                else {
                                    setAffiliateUser(JSON.parse(localStorage.getItem(auth.userDetails)))
                                }
                            }
                            catch(error){
                                setAffiliateUser(null)
                            }
                            
                        }
                        
                    }
                    catch(error){
                        setAffiliateUser(null)
                    }
                }
                else {
                    response = await axios.get(GET_AFFILIATE_TOKEN_BY_ID + referalId)
                    if(response.data){
                        localStorage.setItem(auth.affiliateKey, response.data.token)
                        localStorage.setItem( auth.affiliateUserDetails ,JSON.stringify(response.data.data))
                        setAffiliateUser(response.data.data)
                    } 
                }
                
            }
            else if(localStorage.getItem(auth.affiliateKey)){
                try{
                    const decodedToken = jwt.verify(localStorage.getItem(auth.affiliateKey), auth.secretKey);
                    if(decodedToken.exp * 1000 < Date.now()){
                        localStorage.removeItem(auth.affiliateKey)
                        localStorage.removeItem(auth.affiliateUserDetails);
                        setAffiliateUser(null)
                    }
                    else {
                        setAffiliateUser(JSON.parse(localStorage.getItem(auth.affiliateUserDetails)))
                    }
                }
                catch(error){
                    setAffiliateUser(null)
                }
            }
            
        }
        catch(error) {
            console.log(error)
        }
    }

    return (
        <AffiliateContext.Provider  value={{
            getAffiliateToken,
            affiliateUser
        }
        } {...props} />
    );
}

 
export { AffiliateProvider, AffiliateContext }