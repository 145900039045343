var config = {
    auth: {
        publicKey: "jwtToken",
        secretKey: "cosmetic_key",
        userDetails: 'userDetails',
        authorizedKey: "authorizedKey",
        affiliateKey : "affiliateKey",
        affiliateUserDetails: "affiliateUserDetails"
    },
    doctoAvailablity: {
        available: "available",
        book: "book",
        notWorking: "not working"
    },
    userRole: {
        admin: "admin",
        doctor: "doctor",
        patient: "patient",
        affiliate: "affiliate"
    },
    userGender: {
        male: "male",
        female: "female",
        other: "other",
    },
    doctorDocument: {
        maxFiles: 5
    },
    handleBookingRequestPopup: {
        viewPatient : "viewPatient",
        acceptAppointment: "acceptAppointment",
        cancelAppointment: "cancelAppointment"
    },
    mapToken : process.env.REACT_APP_MAP_TOKEN,
    doctorProfileImage: {
        maxFiles: 1,
        path: "/files/doctor-profile-images/" 
    },
    doctorPracticeData: {
        maxFiles: 1,
        path: "/files/doctor-practice-data/"
    },
    patientProfileImage: {
        maxFiles: 1,
        path: "/files/patient-profile-images/" 
    },
    affiliateProfileImage: {
        maxFiles: 1,
        path: "/files/affiliate-profile-images/" 
    },
    contactIssueOption: {
        application: "Applications",
        booking: "Bookings",
        findingDoctor: "Finding Doctor",
        techSupport: "Tech support",
        others: "Others",
    },
    dealPdfPath: "/files/deal-pdf/",
    ratingRequest: {
        requested: 'requested',
        approved: 'approved',
        rejected: 'rejected'
    },
    dealImagePath: "/files/deal-images/",
    dealVideoPath: "/files/deal-videos/",
    isFeatured: {
        no: 0,
        yes: 1
    },
    isApproved: {
        no: 0,
        yes: 1
    },
    suggestedSearchImage: {
        maxFiles: 1,
        path: "/files/suggested-searches-images/" 
    },
    mentorDealImagePath: "/files/mentor-deal-images/",
    userSubscriptionPlan: {
        year: 'yearly',
        month: 'monthly',
        day : 'days'
    },
    userSubscriptionPlanPrice: {
        year: '0.10',
        month: '0.5',
        day : '0.1'
    },
    isActive: {
        no: 0,
        yes: 1
    },
    searchRadius: {
        maxRadius: 300,
        minRadius: 50
    },
    modules:{
        appointment:{
            id : 1,
            price : 1
        },
        deals:{
            id : 2,
            price : 1
        },
        mentor:{
            id : 3,
            price : 1
        }
    },
    headerLogo: '/files/logo/logo.png',
    footerLogo: '/files/logo/logo-footer.png',
    iconsPath: {
        play: '/files/icons/play.png',
        videona: '/files/icons/videona.png',
    },
    blogImages: {
        maxFiles: 1,
        path: "/files/blog-images/" 
    },
    dateFilterRange : {
        total : 3,
        appendOnRight: 1,
        removeOnLeft : -4
    },
    dateFilterRangeForSingleDoctor : {
        total : 2,
        appendOnRight: 1,
        removeOnLeft : -3
    }
}

module.exports = config;
