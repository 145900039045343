import React,  { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { AuthProvider } from './context/auth';
import { AffiliateProvider } from './context/affiliate';
import AuthRoute from './components/AuthRoute';
import AdminRoute from './components/AdminRoute';
import AffiliateRoute from './components/AffiliateRoute';

import { SearchProvider } from './context/search';
import PageLoader from './PageLoader';

import './App.css';

const  PatientHome = lazy(() => import( './components/PatientPanel/PatientHome'));
const  DoctorHome = lazy(() => import( './components/DoctorPanel/DoctorHome'));
const  Doctor = lazy(() => import( './components/DoctorPanel/Doctor'));
const  Patient = lazy(() => import( './components/PatientPanel/Patient'));
const  Admin = lazy(() => import( './components/AdminPanel/Admin'));
const  AdminLogin = lazy(() => import( './components/AdminPanel/Login'));
const  Contact = lazy(() => import( './components/PatientPanel/Contact'));
const  Press = lazy(() => import( './components/Common/Press/ListPress'));
const  ViewPressRelease = lazy(() => import( './components/Common/Press/ViewPressRelease'));
const  SearchFaq = lazy(() => import( './components/Common/Faq/SearchFaq'));
const  ViewFaq = lazy(() => import( './components/Common/Faq/ViewFaq'));
const  ViewAboutUs = lazy(() => import( './components/Common/ViewAboutUs'));
const  ListBlog = lazy(() => import( './components/Common/Blog/ListBlog'));
const  ViewBlog = lazy(() => import( './components/Common/Blog/ViewBlog'));
const  DoctorForgotPassword = lazy(() => import( './components/DoctorPanel/DoctorAuth/ForgotPassword'));
const  DoctorResetPassword = lazy(() => import( './components/DoctorPanel/DoctorAuth/ResetPassword'));
const  PatientForgotPassword = lazy(() => import( './components/PatientPanel/PatientAuth/ForgotPassword'));
const  PatientResetPassword = lazy(() => import( './components/PatientPanel/PatientAuth/ResetPassword'));
const  ViewPrivacyPolicy = lazy(() => import( './components/Common/ViewPrivacyPolicy'));
const  ViewTermsAndConditions = lazy(() => import( './components/Common/ViewTermsAndConditions'));
const  Maintenance = lazy(() => import( './Maintenance'));
const  AffiliateHome = lazy(() => import( './components/AffiliatePanel/AffiliateHome'));
const  Affiliate = lazy(() => import( './components/AffiliatePanel/Affiliate'));
const  AffiliateForgotPassword = lazy(() => import( './components/AffiliatePanel/AffiliateAuth/ForgotPassword'));
const  AffiliateResetPassword = lazy(() => import( './components/AffiliatePanel/AffiliateAuth/ResetPassword'));
const  PatientRegister = lazy(() => import( './components/PatientPanel/PatientAuth/PatientRegister'));
const  DoctorDetails = lazy(() => import( './components/PatientPanel/DoctorPage/DoctorDetails'));

// const  PatientRegister = lazy(() => import( './components/PatientPanel/PatientAuth/PatientRegister'));
const App = () => {
  return (
      <div className="app">
        <AuthProvider>
          <AffiliateProvider>
              <SearchProvider >
                <Router>
                  <Suspense fallback={<PageLoader />} >
                    <Switch>
                        <Route exact path='/' component={PatientHome}/>
                        <AuthRoute exact  path='/login/doctor' component={DoctorHome}/>
                        <Route   path='/doctors/' component={Doctor}/>
                        <Route exact  path='/doctor/:doctorSlug' component={DoctorDetails}/>
                        <Route exact path='/forgotPassword/doctor' component={DoctorForgotPassword}/>
                        <Route exact path='/resetPassword/doctor/:token' component={DoctorResetPassword}/>
                        <Route exact path='/forgotPassword/patient' component={PatientForgotPassword}/>
                        <Route exact path='/resetPassword/patient/:token' component={PatientResetPassword}/>
                        <Route path='/patient/' component={Patient}/>
                        <AdminRoute exact path='/admin/login' component={AdminLogin}/>
                        <Route path='/admin/' component={Admin}/>
                        <Route path='/contact/' component={Contact}/>
                        <Route exact path='/press-releases/:keyword?' component={Press}/>
                        <Route exact path='/press-release/:slug' component={ViewPressRelease}/>
                        <Route exact path='/faq/all' component={SearchFaq}/>
                        <Route exact path='/faq/view/:faqId' component={ViewFaq} />
                        <Route exact path='/about-us' component={ViewAboutUs}/>
                        <Route exact path='/blog/' component={ListBlog}/>
                        <Route exact path='/blog/:slug' component={ViewBlog} />
                        <Route exact path='/privacy-policy' component={ViewPrivacyPolicy}/>
                        <Route exact path='/terms-and-conditions' component={ViewTermsAndConditions}/>
                        <Route exact path='/maintenance' component={Maintenance}/>
                        <AffiliateRoute exact path='/login/affiliate' component={AffiliateHome} />
                        <Route  path='/affiliate' component={Affiliate} />
                        <Route exact path='/forgotPassword/affiliate' component={AffiliateForgotPassword}/>
                        <Route exact path='/resetPassword/affiliate/:token' component={AffiliateResetPassword}/>
                        <Route exact  path='/login/patient/:token' component={PatientRegister}/>
                        {/* <Route exact  path='/login/patient/:token' component={PatientRegister}/> */}
                    </Switch>
                  </Suspense>  
                </Router>
            </SearchProvider>
          </AffiliateProvider>
        </AuthProvider>
      </div>  
  );
}

export default App;