import React, { useContext } from 'react';
import  { Route, Redirect } from 'react-router-dom'

import { AuthContext } from '../context/auth';
import { userRole } from '../config/bootstrap'

const AuthRoute = ({ component: Component, ...rest}) => {
    const { user } = useContext(AuthContext)
    return ( 
        <Route
           {...rest}
           render={ (props) => 
              user ? 
                     user.userType === userRole.admin ? <Redirect to="/admin" />
                   :  <Redirect to="/" />
                : <Component {...props}/>
           }
        ></Route>   
     );
}
 
export default AuthRoute;
