module.exports = {
    // patient
    LOGIN_PATIENT: '/api/v1/patient/login', 
    REGISTER_PATIENT: '/api/v1/patient/register',
    GET_DOCTOR_SCHEDULE_BY_DATE: '/api/v1/doctor/getDoctorScheduleByDate/',
    GET_DOCTOR_BY_ID: '/api/v1/patient/getDoctorById/',
    GET_DOCTOR_BY_SLUG: '/api/v1/patient/getDoctorBySlug/',
    GET_PATIENT_BY_ID: '/api/v1/patient/getPatientById/',
    ADD_PATIENT_DOCUMENT: '/api/v1/patient/addPatientDocument',
    CREATE_APPOINTMENT: '/api/v1/patient/createAppointment',
    GET_PATIENT_APPOINTMENTS: '/api/v1/patient/getPatientAppointments/',
    CANCEL_PATIENT_APPOINTMENT: '/api/v1/patient/cancelPatientAppointment/',
    REQUEST_PASSWORD_RESET_PATIENT: '/api/v1/users/forgotPassword/patient', 
    PATIENT_RESET_PASSWORD: '/api/v1/users/resetPassword/patient/',

    UPDATE_PATIENT_PROFILE_IMAGE: '/api/v1/patient/updatePatientProfileImage',
    UPDATE_PATIENT_PROFILE: '/api/v1/patient/updatePatientProfile',
    SEARCH_DOCTOR: '/api/v1/patient/searchDoctors',
    GET_ALL_PATIENTS: '/api/v1/patient/getAllPatients',
    DELETE_PATIENT: '/api/v1/patient/delete',
    CREATE_PATIENT_PROFILE: '/api/v1/patient/createPatientProfile',
    GET_PATIENT_BY_ID_FROM_ADMIN: '/api/v1/patient/getPatientByIdFromAdmin/',
    GET_PATIENT_DOCUMENT: '/api/v1/patient/getPatientDocument/',
    DELETE_PATIENT_DOCUMENT: '/api/v1/patient/deletePatientDocument',
    
    REGISTER_PATIENT_BY_SOCIAL_SITE: '/api/v1/patient/registerBySocialSite',
    SEND_MESSAGE: '/api/v1/contact/sendMessage',
    UPLOAD_USER_IMAGES: '/api/v1/patient/uploadProfileImage',
 
    //doctor
    LOGIN_DOCTOR: '/api/v1/users/login',
    REGISTER_DOCTOR: '/api/v1/users/register/doctor',
    REQUEST_PASSWORD_RESET_DOCTOR: '/api/v1/users/forgotPassword/doctor', 
    DOCTOR_RESET_PASSWORD: '/api/v1/users/resetPassword/doctor/',
    GET_DOCTOR_BY_TOKEN: '/api/v1/users/getToken/doctor/',
    UPLOAD_DOCTOR_IMAGES: '/api/v1/doctor/uploadDoctorProfileImage/',
    
    EDIT_SITE_CONTENT: '/api/v1/siteContents/editSiteContent',
    GET_SITE_CONTENT: '/api/v1/siteContents/getSiteContent',

    GET_AVAILABLE_TIME: '/api/v1/doctor/getAvailableTime',
    ADD_DOCTOR_SCHEDULE: '/api/v1/doctor/addDoctorSchedule',
    EDIT_DOCTOR_SCHEDULE: '/api/v1/doctor/editDoctorSchedule/',
    SET_DOCTOR_NOT_WORKING: '/api/v1/doctor/setDoctorNotWorking',
    GET_BOOKING_REQUEST: '/api/v1/doctor/getBookingRequest/',
    ACCEPT_APPOINTMENT: '/api/v1/doctor/acceptAppointment/',
    CANCEL_APPOINTMENT: '/api/v1/doctor/cancelAppointment/',
    GET_APPOINTMENTS: '/api/v1/doctor/getAppointments/',
    GET_ALL_DOCTORS: '/api/v1/patient/getAllDoctors',
    GET_FEATURED_DOCTORS: '/api/v1/patient/getFeaturedDoctors',

    UPDATE_DOCTOR_PROFILE_IMAGE: '/api/v1/doctor/updateDoctorProfileImage',
    UPDATE_DOCTOR_PROFILE: '/api/v1/doctor/updateDoctorProfile',
    UPDATE_PATIENT_BEFORE_IMAGE: '/api/v1/doctor/updatePatientBeforeImage',  
    UPDATE_PATIENT_AFTER_IMAGE: '/api/v1/doctor/updatePatientAfterImage',  
    UPDATE_PRACTICE_VIDEO: '/api/v1/doctor/updatePracticeVideo',  
    GET_PATIENT_BY_TOKEN: '/api/v1/users/getToken/patient/',
    RESET_DOCTOR_PASSWORD: '/api/v1/doctor/resetPassword/',

    GET_DOCTOR_EDUCATION:   '/api/v1/doctor/getDoctorEducation/',
    ADD_DOCTOR_EDUCATION:   '/api/v1/doctor/addDoctorEducation/',
    EDIT_DOCTOR_EDUCATION:   '/api/v1/doctor/editDoctorEducation/',
    DELETE_DOCTOR_EDUCATION:   '/api/v1/doctor/deleteDoctorEducation/',
    GET_DOCTOR_TRAINING:   '/api/v1/doctor/getDoctorTraining/',
    ADD_DOCTOR_TRAINING:   '/api/v1/doctor/addDoctorTraining/',
    EDIT_DOCTOR_TRAINING:   '/api/v1/doctor/editDoctorTraining/',
    DELETE_DOCTOR_TRAINING:   '/api/v1/doctor/deleteDoctorTraining/',

    //guest
    GET_ALL_LANGUAGES: '/api/v1/users/getAllLanguages',
    GET_ALL_DESEASES: '/api/v1/users/getAllDiseases',
    GET_ALL_SPECIALIST: '/api/v1/users/getAllSpecailist',
    GET_ALL_STATES: '/api/v1/users/getUsaStates',
    GET_ALL_LICENSES: '/api/v1/users/getAllLicenses',

    //save for later
    GET_SAVE_FOR_LATER: '/api/v1/patient/getSaveForLaters',
    ADD_SAVE_FOR_LATER: '/api/v1/patient/addSaveForLaters',

    //doctor chat
    GET_CONVERSATION_LIST: '/api/v1/doctor/getConversationList',
    GET_CONVERSATION: '/api/v1/doctor/getConversation/',
    SEND_TEXT_MESSAGE: '/api/v1/doctor/sendMessage/',
    // admin 
    SAVE_PRESS_RELEASE: '/api/v1/admin/press-release/add',
    GET_ALL_PRESS: '/api/v1/admin/press-release/list',
    DELETE_PRESS: '/api/v1/admin/press-release/delete',
    VIEW_PRESS: '/api/v1/admin/press-release/getPressRelease',
    EDIT_PRESS: '/api/v1/admin/press-release/update',
    SEARCH_PRESS: '/api/v1/admin/press-release/searchPress',

    //deal 
    GET_ALL_DEAL: '/api/v1/deal/getAllDeals',
    GET_DOCTOR_DEALS: '/api/v1/deal/getDoctorDeals',
    CREATE_DEAL: '/api/v1/deal/createDeal',
    EDIT_DEAL: '/api/v1/deal/editDeal/',
    DELETE_DEAL: '/api/v1/deal/deleteDeal/',
    DOWNLOAD_DEAL: '/api/v1/deal/downloadDeal/',
    GET_DEAL_BY_ID: '/api/v1/deal/getDealById/',
    UPLOAD_DEAL_IMAGE: '/api/v1/deal/uploadDealImage/',
    DELETE_DEAL_IMAGE: '/api/v1/deal/deleteDealImage/',
    UPLOAD_DEAL_VIDEO: '/api/v1/deal/uploadDealVideo/',
    DELETE_DEAL_VIDEO: '/api/v1/deal/deleteDealVideo/',
    GET_DEAL_IMAGES_BY_ID: '/api/v1/deal/getDealImagesById/',
    GET_DEAL_VIDEOS_BY_ID: '/api/v1/deal/getDealVideosById/',
    DELETE_DEAL_IMAGES_BY_ID: '/api/v1/deal/deleteDealImageById/',
    DELETE_DEAL_VIDEOS_BY_ID: '/api/v1/deal/deleteDealVideoById/',
    ADD_DEAL_IMAGE: '/api/v1/deal/addDealImage/',
    ADD_DEAL_VIDEO: '/api/v1/deal/addDealVideo/',

    //admin specific
    DELETE_DOCTOR: '/api/v1/users/deleteUser',
    CHANGE_DOCTOR_STATUS: '/api/v1/users/changeDoctorStatus',
    ADD_DOCTOR: '/api/v1/doctor/addDoctor',
    ADMIN_GET_DOCTOR_BY_ID: '/api/v1/doctor/getDoctorById/',

    //doctor reveiws
    ADD_RATING: '/api/v1/rating/addRating/',
    ACCEPT_REJCET_REVIEW: '/api/v1/rating/acceptRejectRatingRequest/',
    GET_DOCTOR_REVIEW_GUEST: '/api/v1/rating/getDoctorReveiwGuest/',
    GET_DOCTOR_REVIEW: '/api/v1/rating/getDoctorReveiw/',

    //doctorKeys
    SAVE_DOCTOR_KEYS: '/api/v1/doctor/saveDoctorKeys/',
    GET_DOCTOR_KEYS: '/api/v1/users/getKeys/',

    //Payment
    BUY_DEAL: '/api/v1/deal/purchaseDeal/',
    GET_BOUGHT_DEALS : '/api/v1/deal/getBoughtDeals',
    GET_BOUGHT_DEAL_BY_ID: '/api/v1/deal/getBoughtDealById/',
    //faq - admin
    SAVE_FAQ: '/api/v1/admin/faq/add',
    GET_ALL_FAQ: '/api/v1/admin/faq/list',
    DELETE_FAQ: '/api/v1/admin/faq/delete',
    VIEW_FAQ: '/api/v1/admin/faq/view/',
    EDIT_FAQ: '/api/v1/admin/faq/update/',
    SEARCH_FAQ: '/api/v1/admin/faq/searchFaq',
    GET_ALL_FAQ_CATEGORIES: '/api/v1/admin/faq/getAllFaqCategories',

    //common
    GET_ALL_FAQ_BY_CATEGORIES: '/api/v1/faq/getAllFaqByCategories',
    GET_FAQ_BY_ID: '/api/v1/faq/getFaqById/',

    //Blog
    GET_ALL_BLOG: '/api/v1/blog/getAllBlogs',
    GET_BLOG_BY_ID: '/api/v1/blog/getBlogById/',

    SAVE_BLOG: '/api/v1/admin/blog/addBlog',
    EDIT_BLOG: '/api/v1/admin/blog/editBlog/',
    DELETE_BLOG: '/api/v1/admin/blog/deleteBlog/',    
    VIEW_BLOG: '/api/v1/admin/blog/viewBlog/',
    GET_ALL_BLOG_ADMIN: '/api/v1/admin/blog/getAllBlogsAdmin',

    SAVE_TREATMENT: '/api/v1/admin/treatment/addTreatment',
    EDIT_TREATMENT: '/api/v1/admin/treatment/editTreatment/',
    DELETE_TREATMENT: '/api/v1/admin/treatment/deleteTreatment/',    
    GET_TREATMENT_BY_ID: '/api/v1/admin/treatment/getTreatmentById/',
    GET_ALL_TREATMENT: '/api/v1/admin/treatment/getAllTreatments',

    SAVE_SPECIALTY: '/api/v1/admin/specialty/addSpecialty',
    EDIT_SPECIALTY: '/api/v1/admin/specialty/editSpecialty/',
    DELETE_SPECIALTY: '/api/v1/admin/specialty/deleteSpecialty/',    
    GET_SPECIALTY_BY_ID: '/api/v1/admin/specialty/getSpecialtyById/',
    GET_ALL_SPECIALTY: '/api/v1/admin/specialty/getAllSpecialties',

    UPGRADE_DOCTOR_TO_MENTOR: '/api/v1/mentor/upgradeDoctorToMentor',
    CANCEL_SUBSCRIPTION: '/api/v1/mentor/cancelMentorSubscription',

    //mentor
    GET_DOCTOR_MENTOR_DEALS : '/api/v1/mentor/getDoctorMentorDeals',
    DELETE_MENTOR_DEAL : '/api/v1/mentor/deleteMentorDeal/',
    CREATE_MENTOR_DEAL : '/api/v1/mentor/createMentorDeal',
    UPLOAD_MENTOR_DEAL_IMAGE : '/api/v1/mentor/uploadMentorDealImage/',
    DELETE_MENTOR_DEAL_IMAGE : '/api/v1/mentor/deleteMentorDealImage/',
    EDIT_MENTOR_DEAL : '/api/v1/mentor/editMentorDeal/',
    GET_MENTOR_DEAL_IMAGES_BY_ID : '/api/v1/mentor/getMentorDealImagesById/', 
    DELETE_MENTOR_DEAL_IMAGES_BY_ID : '/api/v1/mentor/deleteMentorDealImageById/', 
    ADD_MENTOR_DEAL_IMAGE : '/api/v1/mentor/addMentorDealImage/',
    GET_MENTOR_DEAL_BY_ID : '/api/v1/mentor/getMentorDealById/',
    GET_ALL_MENTOR_DEALS : '/api/v1/mentor/getAllMentorDeals',
    BUY_MENTOR_DEAL: '/api/v1/mentor/purchaseDeal/',
    GET_BOUGHT_MENTOR_DEALS : '/api/v1/mentor/getBoughtMentorDeals',
    GET_BOUGHT_MENTOR_DEAL_BY_ID: '/api/v1/mentor/getBoughtMentorDealById/',
    GET_MENTOR_DEAL_ORDERS : '/api/v1/mentor/getMentorDealOrders',
    GET_MENTOR_DEAL_ORDER_BY_ID: '/api/v1/mentor/getMentorDealOrderById/',

    //mentor reviews
    ADD_MENTOR_RATING: '/api/v1/rating/addMentorRating/',
    ACCEPT_MENTOR_REJCET_REVIEW: '/api/v1/rating/acceptRejectMentorRatingRequest/',
    GET_MENTOR_REVEIW_FOR_DOCTOR: '/api/v1/rating/getMentorReveiwforDoctor/',
    GET_MENTOR_REVIEW: '/api/v1/rating/getMentorReveiw',
    GET_MENTOR_BY_ID: '/api/v1/mentor/getMentorById/',
    CHECK_IF_MENTOR_REVIEW_EXIST: '/api/v1/rating/checkIfMentorReveiwExist/',
    CHECK_IF_REVIEW_EXIST: '/api/v1/rating/checkIfReveiwExist/',

    //Subscription 
    SUBSCRIBE_TO_MODULES: '/api/v1/subscription/subscribetoModules',
    GET_SUBSCRIBE_MODULES: '/api/v1/subscription/getSubscribedModules',
    CANCEL_DOCOTOR_SUBSCRIPTION: '/api/v1/subscription/cancelDoctorSubscription/',
    GET_ALL_MODULES: '/api/v1/subscription/getAllModules',
    CHECK_IF_MODULE_IS_SUBSCRIBED: '/api/v1/subscription/checkIfModuleIsSubscribed/',
    EDIT_MODULE: '/api/v1/subscription/editModule/', 
    GET_MODULE_BY_ID: '/api/v1/subscription/getModuleById/',
    SUBSCRIBE_AGIAN: '/api/v1/subscription//subscribeAgain/',

    //subscription admin
    ADMIN_SUBSCRIBE_TO_MODULES: '/api/v1/subscription/subscribetoModulesByTrial',
    ADMIN_EDIT_TRAIL_MODULE : '/api/v1/subscription/editTrailModule/',
    ADMIN_REMOVE_TRAIL_MODULE : '/api/v1/subscription/removeTrailModule/',
    ADMIN_GET_TRAIL_MODULE_BY_ID : '/api/v1/subscription/getModuleSubscriptionByDoctorId/',

    //deals
    GET_DOCTOR_DEAL_ORDERS: '/api/v1/deal/getDealOrders',
    GET_DOCTOR_DEAL_ORDER_BY_ID: '/api/v1/deal/getDealOrderById/',

    //before and after image 
    ADD_BEFORE_AFTER_IMAGE: '/api/v1/doctor/addBeforeAndAfterImage',
    GET_FIRST_BEFORE_AFTER_IMAGE: '/api/v1/doctor/getFirstBeforeAndAfterImage',
    GET_BEFORE_AFTER_IMAGE_BY_ID: '/api/v1/doctor/getBeforeAndAfterImageById/',
    GET_BEFORE_AFTER_IMAGES : '/api/v1/doctor/getBeforeAndAfterImages',
    EDIT_BEFORE_AFTER_IMAGE_BY_ID: '/api/v1/doctor/editBeforeAndAfterImageById/',
    DELETE_BEFORE_AFTER_IMAGE_BY_ID: '/api/v1/doctor/deleteBeforeAndAfterImageById/',
    UPLOAD_PATEINT_BEFORE_IMAGE: '/api/v1/doctor/uploadBeforeImage/',
    UPLOAD_PATEINT_AFTER_IMAGE: '/api/v1/doctor/uploadAfterImage/',
    SEND_INSTAGRAM_LINK: '/api/v1/doctor/instagramLinks/',

    //practice data
    ADD_PRACTICE_VIDEO: '/api/v1/doctor/addPracticeVideo',
    GET_FIRST_PRACTICE_VIDEO: '/api/v1/doctor/getFirstPracticeVideo',
    GET_PRACTICE_VIDEO_BY_ID: '/api/v1/doctor/getPracticeVideoById/',
    GET_PRACTICE_VIDEOS : '/api/v1/doctor/getPracticeVideo',
    EDIT_PRACTICE_VIDEO_BY_ID: '/api/v1/doctor/editPracticeVideoById/',
    DELETE_PRACTICE_VIDEO_BY_ID: '/api/v1/doctor/deletePracticeVideoById/',
    UPLOAD_PRACTICE_VIDEO: '/api/v1/doctor/uploadDoctorPracticeVideo/',

    //practice data for patients
    GET_PATIENT_FIRST_BEFORE_AFTER_IMAGE: '/api/v1/patient/getFirstBeforeAndAfterImage/',
    GET_PATIENT_BEFORE_AFTER_IMAGES : '/api/v1/patient/getBeforeAndAfterImages/',
    GET_PATIENT_FIRST_PRACTICE_VIDEO: '/api/v1/patient/getFirstPracticeVideo/',
    GET_PATIENT_PRACTICE_VIDEOS : '/api/v1/patient/getPracticeVideos/',

     //get consultation 
     REQUEST_CONSULTATION: '/api/v1/patient/requestConsultation/',
     GET_CONSULTED_REQUEST : '/api/v1/patient/getConsultedRequest/',
     CHANGE_RESPONDED_STATUS_BY_ID : '/api/v1/patient/changeRepsondedStatusById/',
     GET_PATIENT_REGISTRATION_TOKEN : '/api/v1/patient/getRegistarionToken/',
     REGISTER_PATIENT_FROM_LINK: '/api/v1/patient/registerPatientFromLink',
     GET_ALL_CONSULTED_PATIENT: '/api/v1/patient/getAllConsultedDoctor',

     //affiliate
     AFFILIATE_REGISTER: '/api/v1/affiliate/register/',
     AFFILIATE_LOGIN: '/api/v1/affiliate/login/',
     REQUEST_PASSWORD_RESET_AFFILIATE: '/api/v1/users/forgotPassword/affiliate', 
     AFFILIATE_RESET_PASSWORD: '/api/v1/users/resetPassword/affiliate/',
     GET_AFFILIATE_BY_TOKEN: '/api/v1/users/getToken/affiliate/',
     
     UPDATE_AFFILIATE_PROFILE_IMAGE: '/api/v1/affiliate/updateAffiliateProfileImage/',
     UPDATE_AFFILIATE_PROFILE: '/api/v1/affiliate/updateAffiliateProfile/',
     GET_AFFILIATE_BY_ID : '/api/v1/affiliate/getAffiliateById/',
     GET_AFFILIATE_TOKEN_BY_ID : '/api/v1/affiliate/genterateTokenById/',
     GET_AFFILIATE_APPOINMENT_BY_ID : '/api/v1/affiliate/getAffiliateAppoinmentsById/',
     GET_AFFILIATE_APPOINMENTS : '/api/v1/affiliate/getAffiliateAppoinments/',
 }
