import React, { createContext, useState } from 'react';
import { SEARCH_DOCTOR } from '../config/routes'
import { mapToken, dateFilterRange } from '../config/bootstrap'
import axios from 'axios';
import moment from 'moment'
import $ from 'jquery';
const SearchContext = createContext({
});

var defaultDate =  moment(new Date()).format('YYYY-MM-DD')
let Query = "?";

let params = new URLSearchParams(document.location.search.substring(1));
let keyword = params.get("keyword") ? params.get("keyword") : '';
let location = params.get("location") ? params.get("location") : false;
let specialistId = params.get("specialistId") ? params.get("specialistId") : '';
let languageId = params.get("languageId") ? params.get("languageId") : '';
let diseaseId = params.get("diseaseId") ? params.get("diseaseId") : '';
let gender = params.get("gender") ? params.get("gender") : '';
let distance = params.get("distance") ? params.get("distance") : '';
let latitude = params.get("latitude") ? params.get("latitude") : '';
let longitude = params.get("longitude") ? params.get("longitude") : '';
let date = defaultDate

if(params.get("date") && params.get("date")!== ''){
    var check = moment(params.get("date"), 'YYYY-MM-DD');
    if(check.isValid()){
        date = params.get("date");
    }
}

let defautStartDate = moment(date).format('YYYY-MM-DD');
let defautEndDate= moment(defautStartDate).add(dateFilterRange.total, 'day').format('YYYY-MM-DD')
let defautDateRange = []
for (var m = moment(defautStartDate); m.diff(defautEndDate, 'days') <= 0; m.add(dateFilterRange.appendOnRight, 'days')) {
    defautDateRange.push(m.format('YYYY-MM-DD'));
}

if(keyword!==""){
    Query = Query + 'keyword=' + keyword + '&'
}
if(specialistId!==""){
    Query = Query + 'specialistId=' + specialistId + '&'
}
if(languageId!==""){
    Query = Query + 'languageId=' + languageId + '&'
}
if(diseaseId!==""){
    Query = Query + 'diseaseId=' + diseaseId + '&'
}
if(gender!==""){
    Query = Query + 'gender=' + gender + '&'
}
if(location!==""){
    Query = Query + 'location=' + location + '&'
}
if(distance!=="" && longitude!=="" && latitude!==""){
    Query = Query + 'distance=' + distance  + '&latitude=' + latitude + '&longitude='+ longitude + '&'
}
Query = Query + 'date=' + date + '&'

const  SearchProvider = (props) => {

    const [initLoading, setInitLoading] = useState(true)
    const [ doctors, setDoctors] = useState()
    const [ allDoctorSchedules, setDoctorSchedules] = useState()
    const [ currentLocation, setCurrentLocation ] = useState({
        latitude: '',
        longitude: '',
        address: "",
    })

    const [ selectedLocation, setSelectedLocation ] = useState({
        latitude: '',
        longitude: '',
    })
    const [ searchValues, setSearchValues] = useState({
        keyword: keyword,
        location: location,
        distance: 'any',
        specialistId,
        gender,
        diseaseId,
        languageId,
        date: date
    })
    const [ query , setSearchQuery ] = useState({
        searchQuery: Query,
        date: date
    })

    const [ startDate, setStartDate] = useState(defautStartDate)
    const [ endDate, setEndDate] = useState(defautEndDate)
    const [ dateRange, setDateRange ] = useState(defautDateRange)

    const setDates = (start, end, dates, fetch) => {
        setStartDate(start)
        setEndDate(end)
        setDateRange(dates);
        if(fetch){
            setDoctors(SetSchedules(doctors, dates, start))
        }
    }

    const getAllDoctors = async () => {
        try{
            let sDate = startDate ? startDate : defautStartDate
            let dRange = dateRange ? dateRange : defautDateRange
            var response = await axios.get(SEARCH_DOCTOR + query.searchQuery)
            setDoctorSchedules(response.data)
            setDoctors(SetSchedules(response.data, dRange, sDate))
            setInitLoading(false)
        }
        catch(error) {
            console.log(error)
        }
    }

    const getAllDoctorsOnSubmit = async (queryData, dates, date) => {
        try{
            setInitLoading(true)
            var response = await axios.get(SEARCH_DOCTOR + queryData)
            setDoctorSchedules(response.data)
            setDoctors(SetSchedules(response.data, dates, date))
            setInitLoading(false)
        }
        catch(error) {
            console.log(error)
        }
    }

   
    const SetSchedules = (data, dates, start) => {
        let newData = JSON.parse(JSON.stringify(data));
        newData.forEach( (element ) => {
            if(element.doctor_schedules.length > 0){
                element.count = 7
                element.appointment_schedules= [];
                let appointmentKey = 0;
                let dateKey = 0;
                for (var e = 0; e < element.doctor_schedules.length; e++) {
                     if(  element.doctor_schedules[e].date < start ){
                         dateKey= dateKey+1;
                     }
                     else if( element.doctor_schedules[e].date === start){
                            break;
                     }
                }
                
                
                let dateKeyDuplicate = dateKey;
                dates.forEach((item, index) =>{
                    if( element.doctor_schedules[dateKey] && item === element.doctor_schedules[dateKey].date){
                        element.appointment_schedules[appointmentKey] = element.doctor_schedules[dateKey]
                        appointmentKey = appointmentKey + 1;
                        dateKey = dateKey + 1;
                    }
                    else{
                        element.appointment_schedules[appointmentKey] = {}
                        appointmentKey = appointmentKey + 1;
                    }
                })
                element.noAppoinmentExist = false;
                element.nextAppointmentDate = false;
                if( dateKeyDuplicate !== dateKey){
                    element.noAppoinmentExist = false;
                }
                else{
                    if(element.doctor_schedules[dateKey]){
                        element.nextAppointmentDate = element.doctor_schedules[dateKey].date
                    }
                    else{
                        element.noAppoinmentExist = true
                    }
                    
                }
            }   
           
        })
        return newData;
    }

    const showMore = (id) => {
         let newData = doctors;
         newData = JSON.parse(JSON.stringify(newData));
         newData.forEach( (element ) => {

           if(element.id === id){
                element.count = 12
           }  
           
        })
        setDoctors(newData);
    }

    const appointmentSuccess = (date, userId, doctorAvailableTimeId) => {
        let newSchedules = allDoctorSchedules
        newSchedules.forEach(element => 
            {
                if(parseInt(element.id) === parseInt(userId)){
                   element.doctor_schedules.forEach(item => 
                        {
                            
                            if(item.date === date){
                                let array2 = item.doctor_schedule_times.filter(subItem => {
                                    if(parseInt(subItem.id) !== parseInt(doctorAvailableTimeId)){
                                        return  true;
                                    }
                                    else{
                                        return false
                                    }
                                    
                                })
                                item.doctor_schedule_times = array2;
                            }
                        }
                    )
                }
            }
        )
        setDoctorSchedules(newSchedules);
        setDoctors(SetSchedules(newSchedules, dateRange, startDate))    
    }

    const changeQuery = (newQuery, date, fetchAgain, dates) => {
        setSearchQuery({
            searchQuery: newQuery,
            date: date
        })
        if(fetchAgain){
            getAllDoctorsOnSubmit(newQuery, dates, date)
        }
    }

    const changeSearchValues = (date, location, keyword, distance = 'any') => {
        let values = searchValues;
        values.keyword = keyword;
        values.date = date;
        values.location = location
        values.distance = distance
        setSearchValues(values)
    } 


    const getCurrentLocation = () => {
        if (!navigator.geolocation) {
            alert('Geolocation is not supported by your browser');
        } 
        else {
            navigator.geolocation.getCurrentPosition(success, error);
        }
        function success(position) {
            if(currentLocation.address === ""){
                const latitude  = position.coords.latitude;
                const longitude = position.coords.longitude;
                var request = new XMLHttpRequest();
                var method = 'GET';
                var url = 'https://maps.googleapis.com/maps/api/geocode/json?latlng='+latitude+','+longitude+'&sensor=true&key='+ mapToken
    
                ;
                var async = true;
    
                request.open(method, url, async);
                request.onreadystatechange = function(){
                    if(request.readyState === 4 && request.status === 200){
                        var data = JSON.parse(request.responseText);
                        var address = data.results[0];
                        let currentLocation = {
                            latitude : latitude,
                            longitude: longitude,
                            address: address && address.formatted_address ? address.formatted_address : ""
                        }
                        setCurrentLocation(currentLocation);
                        let selectedLocation = {
                            latitude : latitude,
                            longitude: longitude,
                        }
                        setSelectedLocation(selectedLocation);
                        if( address && address.formatted_address){
                            $('#home-location').val(address.formatted_address);
                            $('#location-search').val(address.formatted_address);
                            $('#left-location').val(address.formatted_address);
                        }
                    
                    }
                } ;
                request.send();
            }
        }
        
        function error() {
            console.log('Unable to retrieve your location');
        }
    }

    const updateSelectedLocation = (lat, lan) => {
        let selectedLocation = {
            latitude : lat,
            longitude: lan,
        }
        setSelectedLocation(selectedLocation);
    }

    return (
      <SearchContext.Provider value={{ initLoading, doctors, getAllDoctors, getAllDoctorsOnSubmit, 
        appointmentSuccess, changeQuery, query, searchValues, changeSearchValues, 
        currentLocation, getCurrentLocation , selectedLocation, updateSelectedLocation, 
        setDates, startDate, endDate, dateRange, showMore }} {...props} />
    );
}
 
export { SearchProvider, SearchContext }